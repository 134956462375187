import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import GoogleMapReact from 'google-map-react';
import config from '../../configs/app';
import faceitIcon from './../../images/contact-us/pin-faceit.png';
import styles from './styles';

const faceIT = {
  lat: 47.84756260004396,
  lng: 35.13610910560696,
};

const zoom = 19;

const Content = () => {
  return (
    <div className='marker-info'>
      <div className='marker-info__header'>
        FaceIT
      </div>
      <div className='marker-info__content'>
        Volgogradskaya 26a
      </div>
    </div>
  );
};

const Map = () => {
  const [google, setMap] = useState(null);

  useEffect(() => {
    if(!google)
      return;

    const { map, maps } = google;

    const marker = new maps.Marker({
      position: faceIT,
      map,
      icon: faceitIcon,
    });

    const info = new maps.InfoWindow({
      content: ReactDOMServer.renderToStaticMarkup(<Content />),
      disableAutoPan: false
    });

    const clickHandlerId = marker.addListener("click", () => {
      info.open(map, marker);
    });

    info.open(map, marker);

    return () => maps.event.removeListener(clickHandlerId);
  }, [google]);

  const handleGoogleApiLoaded = ({ map, maps }) => {
    setMap({ map, maps })
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.mapApiKey }}
      defaultCenter={faceIT}
      defaultZoom={zoom}
      options={{
        styles
      }}
      onGoogleApiLoaded={handleGoogleApiLoaded}
    />
  );
}

export default Map;
