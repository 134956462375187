import React, { useState } from 'react';
import ContactPageForm from '../components/Forms/ContactPageForm';
import Layout from '../components/Layout';
import Map from '../components/Map';
import { Modal } from '../components/ModalForm';
import SEO from '../components/Seo';
import { Thanks } from '../components/Thanks';
import TitleImgBlock from '../components/TitleImgBlock';
import { windowSizeChecker } from '../utils';

const ContactUs = (props) => {
  const [isThanksModalShow, setIsThanksModalShow] = useState(false);
  const {isTablet, isMobile} = windowSizeChecker;
  const onSubmitForm = () => {
    openThanksModal();
  };
  const openThanksModal = () => {
    setIsThanksModalShow(true);
  };

  const hideThanksModal = () => {
    setIsThanksModalShow(false);
  };

  const { content_header, info_block } = props.pageContext.data;

  return (
    <Layout>
      <SEO title="ContactUs" />
      <section className="section">
        <TitleImgBlock title={content_header.title} />
        <div className="map">
          <Map />
          <div id="after-main-baner-section" />
        </div>
        <div className="contacts">
          <div className="contact-info">
            {info_block.map((infoItem) => (
              <div className="contact-info-item" key={infoItem.title}>
                {infoItem.title && (
                  <p className="contact-info-item__title">{infoItem.title}</p>
                )}
                <div className="contact-info-item__links">
                  {infoItem.address && (
                    <p className="office-address">{infoItem.address}</p>
                  )}
                  {infoItem.email && (
                    <a
                      href={`mailto:${infoItem.email}`}
                      children={infoItem.email}
                    />
                  )}
                  {infoItem.social_facebook && (
                    <a href={infoItem.social_facebook} children="Facebook," />
                  )}
                  {infoItem.social_linkedin && (
                    <a href={infoItem.social_linkedin} children="LinkedIn" />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={isTablet() || isMobile() ? '' : `container`}>
            <div className="contacts-form-wrapper">
              <ContactPageForm onSubmit={onSubmitForm} />
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={isThanksModalShow}
        onHide={hideThanksModal}
        children={<Thanks hideThanksModal={hideThanksModal} />}
      />
    </Layout>
  );
};

export default ContactUs;
