import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  isRelevanceEmail,
  isRequired,
  maxLength,
} from '../../constants/validationFunction';
import useForm from '../../hooks/useForm';

const ContactPageForm = ({ onHide = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const emptyField = t('contactUsModal.err.empty');
  const invalidEmailField = t('contactUsModal.err.errEmail');
  const invalidPhoneField = t('contactUsModal.err.invalidPhoneField');

  const initialForm = [
    {
      label: `${t('contactUsModal.name')}`,
      name: 'nameUser',
      validators: [{ func: isRequired, message: emptyField }],
    },
    {
      label: `${t('contactUsModal.email')}`,
      name: 'emailUser',
      extraParams: { type: 'email' },
      validators: [
        { func: isRequired, message: emptyField },
        { func: isRelevanceEmail, message: invalidEmailField },
      ],
    },
    {
      label: `${t('contactUsModal.number')}`,
      name: 'numberUser',
      extraParams: { type: 'number' },
      validators: [
        { func: isRequired, message: emptyField },
        {
          func: maxLength,
          message: invalidPhoneField,
          extraParams: { max: 16 },
        },
      ],
    },
    {
      label: `${t('contactUsModal.text')}`,
      name: 'textareaUser',
      extraParams: { as: 'textarea', rows: 1 },
      validators: [{ func: isRequired, message: emptyField }],
    },
    {
      label: `${t('contactsPage.select_budget')}`,
      name: 'select',
      type: 'radio',
      options: ['< $30 000', '< $60 000', '< $100 000', '> $100 000'],

      validators: [{ func: isRequired, message: emptyField }],
    },
  ];

  const onSubmitHandler = (formData = {}) => {
    onHide();
    onSubmit(formData);
    resetFormState();
  };

  const {
    data,
    errors,
    onChange,
    isValid,
    isInvalid,
    handleSubmit,
    resetFormState,
  } = useForm(onSubmitHandler, initialForm);

  const inputTypeCheck = ({ fieldGroup }) => {
    switch (fieldGroup.type) {
      case 'radio':
        return (
          <div className="radio-wrapper">
            {fieldGroup.options.map((option) => (
              <div
                key={option}
                onClick={() =>
                  onChange({ target: { name: fieldGroup.name, value: option } })
                }
                children={option}
                className={`radio-btn ${
                  option === data[fieldGroup.name] ? 'radio-btn__active' : ''
                }`}
              />
            ))}
          </div>
        );
      default:
        return (
          <Form.Control
            name={fieldGroup.name}
            isValid={isValid(fieldGroup.name)}
            isInvalid={isInvalid(fieldGroup.name)}
            onChange={onChange}
            className={errors[fieldGroup.name] ? 'error-input' : ''}
            {...fieldGroup.extraParams}
          />
        );
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {initialForm.map((fieldGroup) => (
        <Form.Group key={fieldGroup.name} bsPrefix="">
          {fieldGroup.label && (
            <Form.Label
              className={
                errors[fieldGroup.name]
                  ? 'error-msg'
                  : `${
                      data[fieldGroup.name]?.length
                        ? 'text-gray-light'
                        : 'text-dark'
                    }`
              }
            >
              {fieldGroup.label}
            </Form.Label>
          )}
          {inputTypeCheck({ fieldGroup })}
          {fieldGroup.label && errors[fieldGroup.name] && (
            <Form.Label className="error-msg">
              {errors[fieldGroup.name]}
            </Form.Label>
          )}
        </Form.Group>
      ))}

      <Form.Group className="form-group-btn">
        <button className={'btn submit'} type="submit">
          {t('contactsPage.submit')}
        </button>
      </Form.Group>
    </Form>
  );
};

export default ContactPageForm;
